:root {
  --light: #faf8f8;
  --dark: #141021;
  --secondary: #284b63;
  --tertiary: #84a59d;
  --visited: #afbfc9;
  --gray: #4e4e4e;
  --lightgray: #f0f0f0;
  --outlinegray: #cacaca;
}

[saved-theme="dark"] {
  --light: #1e1e21 !important;
  --dark: #fbfffe !important;
  --secondary: #6b879a !important;
  --visited: #4a575e !important;
  --tertiary: #84a59d !important;
  --gray: #d4d4d4 !important;
  --lightgray: #292633 !important;
  --outlinegray: #444444 !important;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light);
}

pre, code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

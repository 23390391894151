.config-item > input[type="checkbox"] {
  visibility: hidden;
  margin-right: 2em;
}

.config-item {
  margin: 1em;
  position: relative;
  display: flex;
}

.config-item h3 {
  margin: 0;
}

.config-item > .desc {
  display: inline-block;
}

.config-item > label {
  padding: 2px;
  border: 1px solid var(--outlinegray);
  cursor: pointer;
  height: 18px;
  width: 18px;
  left: 0;
  top: 3px;
  position: absolute;
  border-radius: 5px;
  margin-right: 2em;
}

.config-item > label:after {
  content: "";
  margin: 2px;
  top: 0;
  left: 0;
  position: absolute;
  height: 12px;
  width: 12px;
  opacity: 0;
  border-radius: 3px;
  background-color: var(--secondary);
}

input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

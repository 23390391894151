.visualization {
  position: relative;
  min-height: 300px;
}

.spacer {
  height: 0em;
}

.visualization:hover > .overlay {
  opacity: 0.1;
} 

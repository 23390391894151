.settings-item {
  min-width: 30px;
}

.settings-item, .settings-item * {
  cursor: pointer;
}

.settings-container {
  width: 100%;
  height: 100vh;
  position: sticky;
  margin-bottom: -100vh;
  top: 0;
  z-index: 1;
  pointer-events: none;
}

.settings-bar {
  display: flex;
  right: 2em;
  top: calc(100vh - 4.5em);
  position: absolute;
  pointer-events: all;
}

.settings-item svg {
  opacity: 0.3;
  transition: opacity 0.3s ease;
}

.settings-item svg:hover {
  opacity: 1;
}

.helpbutton {
  margin-right: 2em;
}

.helpbutton svg {
  fill: var(--gray);
  width: 24px;
}

.darkmode svg {
  position: absolute;
  width: 24px;
  height: 24px;
  fill: var(--gray);
}
